/* eslint-disable react-hooks/exhaustive-deps */
import qs from 'qs'
import { INITIAL_CATALOG_FILTERS } from '../vars'
import { filter, isEmpty } from 'rambda'
import { navigate, useLocation } from '@reach/router'
import { useEffect, useLayoutEffect } from 'react'

const getAgeParams = (age) => {
  const [minAge, maxAge] =
    Array.isArray(age) && age.length === 2 ? age : [undefined, undefined]

  return filter((param) => typeof param !== 'undefined', {
    pt_age_min: minAge,
    pt_age_max: maxAge
  })
}

export const getCatalogQuery = (state) =>
  qs.stringify({
    pt_lang: state.language,
    pt_t_type: state.client,
    pt_s_format: state.sessionFormat,
    pt_wrkrs: state.workAreas || [],
    pt_aprchs: state.approaches || [],
    pt_gender: state.gender,
    pt_friendliness: state.friendliness,
    pt_how_feeling: state.howFeeling,
    pt_prices: state.prices,
    pt_source: state.source,
    pt_source_id: state.sourceId,
    pt_is_urgent: state.isUrgent,
    pt_promocode: state.promocode || undefined,
    pt_is_work_with_teens: state.isWorkingWithTeenagerPatients,
    ...getAgeParams(state.psychologistAge)
  })

export const PT_SOURCE_QUERY_PARAM = 'pt_source'

export const parseCatalogQuery = (query) => {
  const parsed = qs.parse(query, { ignoreQueryPrefix: true })

  return {
    workAreas: parsed.pt_wrkrs,
    approaches: parsed.pt_aprchs,
    gender: parsed.pt_gender,
    client: parsed.pt_t_type,
    language: parsed.pt_lang,
    source: parsed.pt_source,
    psychologistAge:
      parsed.pt_age_min && parsed.pt_age_max
        ? [Number(parsed.pt_age_min), Number(parsed.pt_age_max)]
        : [],
    friendliness: parsed.pt_friendliness
      ? JSON.parse(parsed.pt_friendliness)
      : false,
    sessionFormat: parsed.pt_s_format,
    howFeeling: parsed.pt_how_feeling,
    prices: parsed.pt_prices,
    isUrgent: parsed.pt_is_urgent ? JSON.parse(parsed.pt_is_urgent) : false,
    promocode: parsed.pt_promocode,
    isWorkingWithTeenagerPatients: parsed.pt_is_work_with_teens
      ? JSON.parse(parsed.pt_is_work_with_teens)
      : false
  }
}

const notEmpty = (v) =>
  v !== undefined && (typeof v !== 'object' || !isEmpty(v))

export const useQuerySave = ({ enabled, cache }) => {
  const [storedValue, setStoredValue] = cache
  const { origin, pathname, search } = useLocation()

  useLayoutEffect(() => {
    if (!enabled) {
      return
    }
    const queryObject = filter(notEmpty, parseCatalogQuery(search))

    setStoredValue({ ...INITIAL_CATALOG_FILTERS, ...queryObject })
  }, [])

  useEffect(() => {
    if (!enabled) {
      return
    }
    const queryString = getCatalogQuery(storedValue ?? {})

    if (queryString) {
      const url = `${origin}${pathname}?${queryString}`

      navigate(url, { replace: false })
    }
  }, [storedValue])
}
